<template>
  <section id="notifications-admin">
    <h1>Criar Notificação</h1>
    <div class="header">
      <!-- 
      Future feature...
      <b-form-group>
        <label for="people">
          Usuários que irão receber
        </label>
        <v-checkbox-select
          id="people"
          optionLabel="label"
          trackBy="value"
          :value="people"
          :options="users"
          searchable
          multiple
          v-on:onChange="changeSelectedPeople"
        />
      </b-form-group> -->
      <b-form-group>
        <label for="type">
          Tipo
        </label>
        <b-input
          v-model="notification.type"
          placeholder="Descrever"
        />
      </b-form-group>
      <b-form-group>
        <label for="subtype">
          Sub-tipo
        </label>
        <b-input
          v-model="notification.subtype"
          placeholder="Descrever"
        />
      </b-form-group>
      <b-form-group>
        <label for="icon">
          Redirecionar para
        </label>
        <multiselect 
          v-model="selectedLink" 
          :options="links"
          track-by="value"
          label="label"
          :showLabels="false"
          class="with-border"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
        </template>
        </multiselect>
      </b-form-group>
      <b-form-group>
        <label for="icon">
          Ícone
        </label>
        <multiselect 
          v-model="selectedIcon" 
          :options="iconTypes"
          track-by="value"
          label="label"
          :showLabels="false"
          class="with-border"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
        </template>
        </multiselect>
      </b-form-group>
    </div>
    <section class="content">
      <b-form-group>
        <label for="text">Conteúdo</label>
        <validation-provider name="´laudo" :rules="{ required: true }">
          <VueEditor
            id="text"
            v-model="notification.data.text"
            :editorToolbar="customToolbar"
          />
        </validation-provider>
      </b-form-group>
      <b-form-group>
        <label>Pré-visualização</label>
        <v-notification-content
          :notifications="[notification]"
        />
      </b-form-group>
    </section>
    <section class="send-btn-container">
      <b-button
        variant="primary"
        :disabled="!validNotification"
        @click="confirmationModal"
      >
        Enviar notificação
      </b-button>
    </section>
  </section>
</template>

<script>
import ChevronDown from '@/assets/icons/chevron-down.svg'

// import checkboxselect from '@/components/LabeledCheckboxSelect'
import { VueEditor } from "vue2-editor"

import NotificationContent from '@/components/Notifications/NotificationContent.vue'

export default {
  components: {
    VueEditor, ChevronDown,
    'v-notification-content': NotificationContent,
    // 'v-checkbox-select': checkboxselect,
  },

  data(){
    return {
      notification: {
        type: null,
        subtype: null,
        data: {
          text: '',
          icon: 'info',
          path: null,
        }
      },

      users: [],
      people: [],
      user: JSON.parse(localStorage.getItem('user')),
      selectedIcon:  { label: 'Informação' , value: 'info' },
      selectedLink: { label: 'Não redirecionar' , value: null },
      iconTypes: [
        { label: 'Informação' , value: 'info' },
        { label: 'Atenção' , value: 'danger' },
        { label: 'Aviso' , value: 'warning' } 
      ],
      links: [
        { label: 'Não redirecionar' , value: null },
        { label: 'Visão geral' , value: '/visao-geral' },
        { label: 'Pacientes' , value: '/pacientes' },
        { label: 'Agenda' , value: '/agenda' },
        { label: 'Pré-consulta' , value: '/pre-consulta' },
        { label: 'Exames' , value: '/exames' },
        { label: 'Financeiro' , value: '/financeiro' },
        { label: 'Estoque' , value: '/estoque' },
        { label: 'Faturamento TISS' , value: '/faturamento-tiss/dashboard' },
        { label: 'Laudos médicos' , value: '/laudos-medicos/laudos-pendentes' },
        { label: 'Eyecare Academy' , value: '/academy' },
      ],

      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ],
    }
  },

  async mounted(){
    // Future feature...
    // await this.getUsers();
  },
  methods: {
    changeSelectedPeople(people){
      this.people = people
    },
    setDefaultForm(){
      this.people = []
      this.selectedIcon = { label: 'Informação' , value: 'info' }
      this.notification = {
        type: null,
        subtype: null,
        data: {
          text: '',
          icon: 'info',
        }
      }
    },
    async confirmationModal() {
      if ( !this.validNotification() ) return
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">
          Enviar notificação?
          </div>`,
        showCancelButton: true,
        confirmButtonColor: 'var(--success)',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(async res => {
        if (res.isConfirmed) {
          await this.sendNotification()
        }
      })
    },
    async sendNotification(){
      const loading = this.$loading.show();
      try {
        // Future feature
        // const peopleToReceive = this.people.map(el => {return el.value} )
        const peopleToReceive = [this.user.id]
        await this.api.sendNotificationToMany(peopleToReceive, this.notification)
        this.$toast.success('Notificação enviada com sucesso!')
        this.setDefaultForm()
      } catch ( err ){
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    validNotification(){
      let valid = true
      // future feature...
      // if(!this.people.length) {
      //   this.$toast.error('Por favor, selecione os usuários que irão receber a notificação')
      //   valid = false
      // }
      if(!this.notification.type) {
        this.$toast.error('Por favor, escolha o tipo da notificação')
        valid = false
      }
      if(!this.notification.data.text) {
        this.$toast.error('Por favor, prencha o contéudo da notificação')
        valid = false
      }

      return valid
    },
    
    async getUsers(){
      const loading = this.$loading.show();
      try {
        const response = await this.api.getUsers()
        this.users = response.data.map(user => {
          return {
            value: user.person ? user.person.id : user.id,
            label: user.name
          }
        })
        this.users.unshift({label: 'Todos', value: 'Todos'})
      } catch ( err ){
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    }
  },

  watch: {
    selectedIcon: function(){
      this.notification.data.icon = this.selectedIcon.value
    },
    selectedLink: function(){
      this.notification.data.path = this.selectedLink.value
    },
  }

}
</script>

<style lang="scss">
#notifications-admin {
  display: flex;
  flex-direction: column;

  h1 {
    font-weight: 700;
    font-size: 1.5rem;
    padding: 40px 20px 20px 20px;
  }

  .header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    width: 100%;
    height: 87px;
    padding: 0 20px;
    border-bottom: 1px solid var(--neutral-200);
    background-color: var(--neutral-000);
    position: relative;

    
  }

  .form-group {
      height: 100%;
      width: 100%;
    }

  .content {
    display: flex;
    flex-direction: row;
    padding: 20px;

    .form-group {
      padding: 10px;
    }
  }

  .send-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
</style>