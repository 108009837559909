<template>
  <div 
    v-infinite-scroll="getNotifications" 
    infinite-scroll-disabled="loading"
    infinite-scroll-distance="5"
  >
  <section
    v-for="(notification, index) in notifications"
    :key="index"
  >
  <div
    id="notification-content" 
    :href="notification.link"
    class="dropdown-item"
    :class="{ 'unread':!notification.visualized }"
  >
    <section class="notification-container" >
      <div class="icon-container" @click="viewNotification(notification)">
        <v-error v-if=" notification.data.icon === 'danger'"/>
        <v-warning v-else-if="notification.data.icon === 'warning'" />
        <v-info v-else />
      </div>
      <div class="notification" @click="viewNotification(notification)">
        <span v-if="notification.type" class="tag">
          {{ notification.type }}
        </span>
        <p class="text" v-html="notification.data.text">
        </p>
        <span class="time">
          {{ getTimeDiff(notification.updated_at) }}
        </span>
      </div>
      <div class="delete-container" v-if="!notification.deleted_at">
        <v-close class="delete-icon" @click.stop="removeNotification(notification)"/>
      </div>
    </section>
  </div>
</section>
</div>
</template>

<script>
import Warning from '@/assets/icons/notification-warning.svg'
import Error from '@/assets/icons/notification-danger.svg'
import Info from '@/assets/icons/notification-info.svg'
import Close from '@/assets/icons/close.svg'

import moment from 'moment'

export default {
  components: {
    'v-close': Close,
    'v-error': Error,
    'v-info': Info,
    'v-warning': Warning,
  },

  props: {
    notifications: Array,
    loading: Boolean,
    removed: Boolean,
  },

  methods: {
    getTimeDiff(time){
      const diff = moment(moment()).diff(time)
      const minutes = moment.duration(diff).asMinutes()
      
      if(minutes > 24*60 && minutes < 60*24*30 )
        return `Há ${Math.floor(minutes/(60*24))} dia${minutes > 2*24*60 ? 's': ''}`
      else if(minutes > 60 && minutes < 60*24)
        return `Há ${Math.floor(minutes/60)} hora${minutes > 60*2 ? 's': ''}`
      if (minutes < 2)
        return 'Agora mesmo'
      else if (minutes < 60)
        return `Há ${Math.floor(minutes)} minutos`
      else 
        return moment(time).format('DD [de] MMMM [de] YYYY')
    },
    viewNotification(notification){
      if(!notification.visualized)
        this.$emit('setAsVisualized', notification);
      if(notification.data.path)
        this.$router.push(notification.data.path);
    },
    removeNotification(notification){
      this.$emit('remove', notification.id);
    },
    getNotifications(){
      this.$emit('getNotifications');
    }
  }
}
</script>

<style lang="scss">
#notification-content {
  display: flex;
  flex-direction: row;

  .notification-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    .notification {
      display: flex;
      text-align: start;
      width: 100%;
      justify-content: start;
      flex-direction: column;
      padding: 10px 0 10px 5px;
      
      .tag {
        font-size: 12px;
        margin: 5px 0;
        padding: 0 10px;
        font-weight: 600;
        width: fit-content;
        border-radius: 100px;
        color: var(--neutral-700);
        background: var(--neutral-200);
      }
      
      .text {
        font-size: 16px;
        height: 300px;
        font-weight: 600;
        overflow: hidden;
        height: fit-content;
        word-break: break-word;
        text-overflow: ellipsis;
        color: var(--type-active);
        white-space: break-spaces;
      }

      .time {
        font-weight: 600;
        font-size: 14px;
        color: var(--type-placeholder);
      }
    } 

    .icon-container {
      svg {
        transform: scale(0.6);
      }
    }

    .delete-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      visibility: hidden;
      
      .delete-icon {
        width: 22px;
        height: 22px;
        fill: var(--neutral-500);
      }
    }
  }

  :hover {
    .delete-container {
      visibility: visible;
    }
  }

}
.unread {
  background-image: linear-gradient(
    to right, 
    var(--neutral-100), 
    var(--neutral-70), 
    var(--neutral-000),
  );
}
.dropdown-item {
  padding: 10px !important;
}
.dropdown-item:hover {
  cursor: pointer;
  background-image: linear-gradient(
    to right, 
    var(--neutral-100), 
    var(--neutral-70), 
    var(--neutral-000),
  );
}
</style>